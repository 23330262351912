<template>
  <div class="competitionManagement">
    <div class="competitionManagement-header">
      <div class="selectCompetition">
        <span>竞赛状态</span>
        <el-select
          v-model="stateValue"
          placeholder="请选择"
          style="margin-left: 10px"
          @change="switchoverState"
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button class="creationBtn" @click="amendTrainTime"
          >修改竞赛时间</el-button
        >
        <router-link :to="{ name: 'creationCompetition' }"
          ><el-button class="creationBtn" style="margin-left: 10px"
            >创建竞赛</el-button
          ></router-link
        >
      </div>
    </div>
    <div class="competitionManagement-table">
      <div class="table-qu">
        <el-table
          :data="tableData"
          height="100%"
          :cell-style="{
            height: '60px',
            color: '#343442',
            fontSize: '14px',
            fontFamily: 'PingFang SC',
            fontWeight: 400,
          }"
          :header-cell-style="{
            fontWeight: '500',
            color: '#14141C',
            background: '#F3F4FA',
            height: '60px',
          }"
        >
          <el-table-column
            label="竞赛名称"
            prop="name"
            align="center"
            width="200"
          ></el-table-column>
          <el-table-column
            label="班级名称"
            prop="class_name"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="竞赛状态"
            prop="status"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            label="队伍人数"
            prop="s_num"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            label="评委数量"
            prop="t_num"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            align="center"
          ></el-table-column>
          <el-table-column
            label="开始时间"
            prop="begin_time"
            align="center"
          ></el-table-column>
          <el-table-column label="结束时间" prop="end_time" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <!-- <el-button
                type="text"
                style="color: #f56c6c"
                v-if="scope.row.flag == 1"
                @click="startCompet(scope.row)"
                >开始比赛</el-button
              > -->
              <el-button
                type="text"
                style="color: #f56c6c"
                @click="endCompett(scope.row)"
                >结束比赛</el-button
              >
              <el-button
                type="text"
                style="color: #1222d8"
                @click="delEdit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                style="color: #fe0001"
                @click="delRace(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="pages-center"
        :current-page="adminPages.currentPageNum"
        :page-size="adminPages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="adminPages.total"
        @current-change="adminCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog title="修改竞赛时间" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="left"
        ref="form"
      >
        <el-form-item label="竞赛名称" prop="trainNme">
          <el-select
            v-model="form.trainNme"
            placeholder="请选择"
            style="width: 90%"
            @change="selectTrain"
          >
            <el-option
              v-for="item in tableData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生名称" prop="studentName">
          <el-select
            v-model="form.studentName"
            placeholder="请选择"
            style="width: 90%"
            @change="selectStudent"
          >
            <el-option
              v-if="options.length && isSelectAll == 2"
              label="全部"
              value="0"
              key="0"
            ></el-option>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-date-picker
            style="width: 90%"
            v-model="form.startTime"
            @change="selectStartTime"
            :picker-options="pickerOptions"
            type="datetime"
            placeholder="选择开始日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="overTime">
          <el-date-picker
            style="width: 90%"
            v-model="form.overTime"
            @change="selectOverTime"
            :picker-options="pickerOptions"
            type="datetime"
            placeholder="选择结束日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAlter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "underscore";
import { startCompet } from "@/utils/apis";
export default {
  name: "competitionManagement",
  data() {
    return {
      typeValue: "",
      stateValue: "",
      tableData: [],
      stateOptions: [
        {
          id: 0,
          label: "全部",
        },
        {
          id: 1,
          label: "未开始",
        },
        {
          id: 2,
          label: "进行中",
        },
        {
          id: 3,
          label: "已结束",
        },
      ],
      adminPagesId: null,
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      dialogVisible: false,
      form: {
        trainNme: "",
        studentName: "",
        startTime: "",
        overTime: "",
      },
      options: [
        {
          value: 1,
          label: "选项1",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      isSelectAll: 1,
      selectName: "",
      rules: {
        trainNme: [
          { required: true, message: "请选择竞赛名称", trigger: "change" },
        ],
        studentName: [
          { required: true, message: "请选择学生名称", trigger: "change" },
        ],
        startTime: [
          {
            type: "date",
            required: true,
            message: "请选择开始日期",
            trigger: "change",
          },
        ],
        overTime: [
          {
            type: "date",
            required: true,
            message: "请选择结束日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getComList();
  },
  methods: {
    selectTrain(val) {
      this.isSelectAll = 2;
      this.form.studentName = "";
      this.$http.axiosGetBy(this.$api.getCom, { com_id: val }, (res) => {
        if (res.code === 200) {
          this.selectName = res.data.name;
          this.$http.axiosGetBy(
            this.$api.studentAdminList,
            { class_id: res.data.c_id },
            (stures) => {
              if (stures.code === 200) {
                if (stures.data.data) {
                  this.options = stures.data.data;
                }
              } else {
                this.$message.warning(stures.msg);
              }
            }
          );
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    getFormatDate(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    selectStudent(val) {},
    selectStartTime(val) {
      // this.form.startTime = this.getFormatDate(val);
    },
    selectOverTime(val) {
      // this.form.overTim = this.getFormatDate(val)
      if (!this.form.startTime) {
        this.$message.warning("请先选择开始时间！");
        this.form.overTime = "";
      } else {
        if (this.form.overTime < this.form.startTime) {
          this.$message.warning("请选择开始时间之后的日期！");
          this.form.overTime = "";
        }
      }
    },
    confirmAlter() {
      if (
        this.form.trainNme &&
        this.form.studentName &&
        this.form.startTime &&
        this.form.overTime
      ) {
        let startTime = this.getFormatDate(this.form.startTime);
        let overTim = this.getFormatDate(this.form.overTime);
        let formData = new FormData();
        formData.append("name", this.selectName);
        formData.append("begin_time", startTime);
        formData.append("end_time", overTim);
        formData.append("s_id", this.form.studentName);
        formData.append("com_id", this.form.trainNme);
        this.$http.axiosPost(this.$api.editCDT, formData, (res) => {
          if (res.code === 200) {
            this.$message.success("修改成功！");
            this.dialogVisible = false;
          } else {
            this.$message.warning(res.msg);
          }
        });
      } else {
        this.$message.warning("请确保每项必填！");
      }
    },
    getComList(id) {
      let param = {};
      if (id) {
        param = {
          mode: 1,
          page: this.adminPages.currentPageNum,
          limit: this.adminPages.eachPageNum,
          type: "page",
          status: id,
        };
      } else {
        param = {
          mode: 1,
          page: this.adminPages.currentPageNum,
          limit: this.adminPages.eachPageNum,
          type: "page",
        };
      }
      this.$http.axiosGetBy(this.$api.comList, param, (res) => {
        if (res.code === 200) {
          this.tableData = _.map(res.data.data, (item) => {
            item.end_time = this.$moment(new Date(item.end_time * 1000)).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            item.begin_time = this.$moment(
              new Date(item.begin_time * 1000)
            ).format("YYYY-MM-DD HH:mm:ss");
            return item;
          });
          //   this.tableData.map((item) => {
          //     item.flag = 1;
          //     return item;
          //   });
          this.adminPages.total = res.data.total;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    delEdit(id) {
      this.$router.push({
        path: "/schooladmin/competition/creationcompetitionone",
        query: {
          editRes: id,
        },
      });
    },
    delRace(row) {
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.axiosGetBy(this.$api.delCom, { com_id: row.id }, (res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: res.msg,
                onClose: () => {
                  this.getComList();
                },
              });
            } else {
              this.$message.warning(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    amendTrainTime() {
      this.dialogVisible = true;
    },
    switchoverState(val) {
      if (val === 0) {
        this.adminPagesId = null;
      } else {
        this.adminPagesId = val;
      }
      this.getComList(this.adminPagesId);
    },
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getComList(this.adminPagesId);
    },
    // startCompet(row) {
    //   let end_time = Date.parse(row.end_time);
    //   let start_time = Date.parse(row.begin_time);
    //   let now_time = Date.parse(new Date());
    //   if (end_time < now_time) {
    //     this.$message({
    //       type: "warning",
    //       message: "竞赛结束时间不能小于当前时间",
    //     });
    //     return false;
    //   } else if (start_time < now_time) {
    //     this.$message({
    //       type: "warning",
    //       message: "竞赛开始时间不能小于当前时间",
    //     });
    //   } else if (start_time && end_time < now_time) {
    //     this.$message({
    //       type: "warning",
    //       message: "竞赛开始和结束时间不能小于当前时间",
    //     });
    //   }else{
    //       this.$confirm("确定开始该场竞赛","提示",{
    //           confirmButtonText:"确定",
    //           cancelButtonText:"取消",
    //       }).then(()=>{
    //           let params={
    //               com_id:row.id,
    //           };
    //         //   this.$http.axiosGetBy(
    //         //       this.$api.com_startTrain,
    //         //       params,
    //         //       (res)=>{
    //         //           if(res.code===200){
    //         //               console.log(res.data)
    //         //           }
    //         //       }
    //         //   )
    //         startCompet(params).then((res)=>{
    //             console.log(res.data)
    //         })
    //       })
    //   }
    // },
    endCompett(row) {
      let end_time = Date.parse(row.end_time);
      let now_time = Date.parse(new Date());
      if (end_time < now_time) {
        this.$message({
          type: "warning",
          message: "该场次比赛已经结束",
          duration: 1000,
        });
        return false;
      }
      this.$confirm("确定结束该场竞赛", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let params = {
          com_id: row.id,
        };
        this.$http.axiosGetBy(
          this.$api.endCompetition,
          params,
          (res) => {
            if (res.code === 200) {
              console.log(res.data);
              this.$message({
                type: "success",
                message: res.msg,
                duration: 1000,
                onClose: () => {
                  this.getComList();
                },
              });
            } else {
              this.$message({
                type: "warning",
                message: res.msg,
                duration: 2000,
              });
            }
          },
          (err) => {
            this.$common.axiosErrorMsg(err);
          }
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog__body {
  display: flex;
  flex-direction: column;
}
/deep/.el-form {
  /*margin: 0 auto;*/
}
/deep/.el-dialog__header {
  background: #0200d8;
  .el-dialog__title {
    color: #ffffff;
    font-size: 16px;
  }
}
/deep/.el-button--primary {
  background: #0200d8;
  border-color: #0200d8;
}
/deep/.pages-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 10px;
}
.competitionManagement {
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
  padding: 30px 30px 0px 30px;
  height: calc(100vh - 146px);
  .competitionManagement-header {
    display: flex;
    justify-content: space-between;
    .selectCompetition {
      color: #343442;
      font-size: 14px;
      font-weight: 500;
    }
    div {
      .creationBtn {
        /*width: 80px;*/
        height: 36px;
        color: #ffffff;
        background: #1222d8;
        border-color: #1222d8;
        line-height: 0;
        padding: 0 12px;
      }
    }
  }
  .competitionManagement-table {
    margin-top: 22px;
    width: 100%;
    height: calc(100% - 62px);
    .table-qu {
      height: calc(100% - 50px);
    }
  }
}
</style>