<template>
    <div class="competition">
        <el-tabs v-model="activeName" type="card" class="education-content tabs-full">
            <el-tab-pane label="竞赛管理" name="raceAdmin">
                <competitionManagement />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import competitionManagement from './../../components/schoolAdmin/competitionManagement'
    export default {
        name: "Competition",
        components: {
            competitionManagement
        },
        data(){
            return {
                activeName:'raceAdmin'
            }
        }
    }
</script>

<style scoped lang="scss">
    .competition{
        height: 100%;
    }
</style>